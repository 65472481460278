<template>
    <span class="tag is-rounded" :class="{
    'is-success': status === 'passed' || status === 'operational' || status === 'complete' || status === 'active',
        'is-danger': status === 'failed' || status === 'faulty',
        'is-warning': status === 'in progress',
        'is-light': !status,
    }">{{ status | ucwords }}</span>
</template>
<script>
export default {
    props: {
        status: String
    }
}
</script>